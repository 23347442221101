import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"

const WeightLossCoaching = (props) => {
  return (
    <>
      <section className="container container-black">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A young man smiling as he prepares his meal."
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-white type-header__primary type-header__primary-l">
          <span className="type-emph type-break__after">FitFarm</span>
          Coaches
        </h2>
        <div className="container-type__white">
          <p className="type-reg type-white type-body">
            It is easy to be confused by all the information out there on weight
            loss and nutrition. Our Omaha coaches will help you make sense of it
            all. We help you find the most optimal prescription and effective
            way to get off the diet roller coaster and lose the weight for good!
            All without a drastic medical treatment, prescription drugs,
            injection, surgery, or expensive clinic. Our coaches are excited to
            partner with you during your health and fitness journey. The
            benefits of having a Certified Nutrition and Weight Loss Coach at
            FitFarm include:
          </p>
          <ul>
            <li className="type-reg type-white type-body li li-yellow">
              <span className="type-heavy type-yellow">
                Quicker and Maintable Results:{" "}
              </span>
              Learn how to set the right goals and create lasting, healthy
              habits that will support those goals.
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              <span className="type-heavy type-yellow">No More Plateaus: </span>
              Our coaches will help you learn what to eat, how to eat, and why
              you eat.
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              <span className="type-heavy type-yellow">
                Individualized Approach:{" "}
              </span>
              No two people are the same. Everyone's weight loss and nutrition
              journey will be different.
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              <span className="type-heavy type-yellow">
                Focus on Creating Consistency NOT Perfection.
              </span>
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              <span className="type-heavy type-yellow">
                Build a healthy relationship with food and yourself:{" "}
              </span>
              Get rid of obsessive thoughts and guilt about food and weight
              loss.
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              <span className="type-heavy type-yellow">
                Be able to look in the mirror and love what you see:{" "}
              </span>
              Our coaches will help you learn what to eat, how to eat, and why
              you eat.
            </li>
          </ul>

          <div className="u-mt-2">
            <Link
              to="/contact-us"
              className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-1"
            >
              Speak with a Coach
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default WeightLossCoaching
