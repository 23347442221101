import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-banner.svg"

const WeightLossGetStarted = (props) => {
  return (
    <>
      <section className="container container-white">
        <div className="img-section__container img-section__container-l">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A happy couple preparing their meal together."
            className="img-section__container-l__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-right"></div>

        <h2 className=" type-heavy type-black type-header__primary type-header__primary-r">
          <span className="type-emph type-white type-break__after">
            Weight Loss
          </span>{" "}
          Programs
        </h2>
        <div className="container-type__black">
          <h3 className="type type-heavy type-black type-h3">
            Free Consultation
          </h3>

          <p className="type-reg type-black type-body u-mt-1">
            Begin your Weight Loss Omaha and Nutrition Program with a free
            consultation with one of our Certified Nutrition Coaches. During a
            consultation we will discuss your goals, your level of motivation
            and commitment to losing weight and improving your nutrition. We
            will work together to assess your current goals, needs, and
            struggles with weight loss and nutrition. We will discuss and
            determine if our program is the right fit for you.
          </p>
          <h3 className="type type-heavy type-black type-h3">
            Three Steps to Achieve your Goals
          </h3>

          <ul className="u-mt-2">
            <li className="type-reg type-black type-body li li-green">
              Contact Us
            </li>
            <li className="type-reg type-black type-body li li-green">
              Schedule your <span className="type-heavy type-green">Free</span>{" "}
              Consultation.
            </li>
            <li className="type-reg type-black type-body li li-green">
              Lose Weight, Reach your Goals, and Feel Great!
            </li>
          </ul>
          <div className="u-mt-2">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black"
            >
              Schedule your Free Consultation
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__black"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default WeightLossGetStarted
