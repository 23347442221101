import React from "react"

import Logo from "assets/images/fitfarm-logo-for-white-background.svg"
const WeightLossMedical = (props) => {
  return (
    <section className="container grid-yellow u-clear-banner">
      <div className="grid-two-col">
        <div>
          <h2 className="type-heavy type-white type-header__secondary">
            <span className="type-emph type-break__after type-header__intro-block ">
              Do I Need to See a Specialist for a Medical Prescription Weight
              Loss Treatment Plan?
            </span>{" "}
          </h2>
        </div>
        <div className="u-p-2 grid-align-center">
          <h3 className="type-black type-body type-heavy type-header__fourth">
            No, You can become healthier naturally
          </h3>
          <p className="type-black type-body type-reg">
            Many people feel natural weight loss efforts are hopeless and seek
            physician prescribed treatment, surgery, and drugs. They have been
            restricted for too long. They haven't addressed their relationship
            with food and their mental and emotional triggers.
          </p>

          <h4 className="type-black type-body type-heavy type-header__fourth">
            Most people can lose weight if they focus on three steps.
          </h4>
          <ul>
            <li className="type-reg type-black type-body li li-green">
              Creating sustainable habits
            </li>
            <li className="type-reg type-black type-body li li-green">
              Building a healthy relationship with food and self.
            </li>
            <li className="type-reg type-black type-body li li-green">
              Understanding not only what to eat but how to eat and what
              motivates you to eat.
            </li>
          </ul>
        </div>
        <div className="grid-two-col-img-container grid-align-center"></div>
      </div>

      <img
        src={Logo}
        className="img-background img-background__white"
        alt="FitFarm Logo reversed nearly transparent"
      />
    </section>
  )
}

export default WeightLossMedical
