import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-banner.svg"

const WeightLossHealthyExample = (props) => {
  return (
    <>
      <section className="container container-white">
        <div className="img-section__container img-section__container-l">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A group of friends sitting around a table eating."
            className="img-section__container-l__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-right"></div>

        <h2 className=" type-heavy type-black type-header__primary type-header__primary-r">
          <span className="type-emph type-white type-break__after">
            A Healthy
          </span>{" "}
          Example
        </h2>
        <div className="container-type__black">
          <p className="type-reg type-black type-body">
            Your nutrition prescription is easy to follow. You will learn how to
            build quality meals and snacks. Meals and snacks will include
            protein, complex carbohydrates, and fat you need to fuel your body
            and optimize your long term success.
          </p>

          <h3 className="type type-heavy type-black type-h3">
            How to plan meals and snacks for success
          </h3>

          <p className="type-reg type-black type-body u-mt-1">
            Divide your plate into sections. Fill one half of your plate with
            plants such as vegetables and fruits. One fourth with quality
            protein, and the last fourth with complex carbohydrates. Also
            include a small, thumb-size serving of fat.
          </p>
          <h3 className="type type-heavy type-black type-h3">
            How to plan meals and snacks for success
          </h3>

          <ul className="u-mt-2">
            <li className="type-reg type-black type-body li li-green">
              1-2 cupped hand of veggies
            </li>
            <li className="type-reg type-black type-body li li-green">
              1 piece of fruit
            </li>
            <li className="type-reg type-black type-body li li-green">
              1-2 palm size of protein
            </li>

            <li className="type-reg type-black type-body li li-green">
              1 fist size of complex carbs
            </li>
            <li className="type-reg type-black type-body li li-green">
              Try our recipies for a healthier and tastier diet.
            </li>
          </ul>
          <div className="u-mt-2">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black"
            >
              Teach me how to eat healthy!
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__black"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default WeightLossHealthyExample
