import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Logo from "assets/images/fitfarm-logo-for-white-background.svg"
const WeightLossOffering = (props) => {
  return (
    <>
      <section className="container container-black">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A grandmother and granddaughter preparing their meal."
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-white type-header__primary type-header__primary-l">
          <span className="type-emph type-break__after">In Person</span> or
          Online
        </h2>
        <div className="container-type__white">
          <h3 className="type type-heavy type-yellow type-h3">
            No Matter your Location, you can work with us!
          </h3>
          <ul className="u-mt-2">
            <li className="type-reg type-white type-body li li-yellow">
              Group Challenges
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              12-Week Mind and Body Reset Program
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              Individual Weight loss and Nutrition Coaching
            </li>
          </ul>
          <h3 className="type type-heavy type-yellow type-h3">
            Do you want to:
          </h3>
          <p className="type-reg type-white type-body u-mt-1">
            Lose weight and keep it off? Stop feeling restricted and under fed?
            Improve your performance? Increase your energy? Get off the food
            guilt roller coaster? Stop feeling restricted? Understand how to
            fuel your body? Improve your sleep? Have more confidence and love
            who you are? All while avoiding a drastic medical treatment,
            prescription drugs, injection, surgery, or expensive clinic?
          </p>
          <div className="u-mt-2">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-1"
            >
              I am Ready to Lose Weight
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default WeightLossOffering
